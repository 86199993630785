.dp-dashboard {
  .filter-section {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .ant-btn {
      margin-right: 16px;
    }
    .date-range {
      width: 12vw;
    }
  }
  .content {
    .divide-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .divider-line {
        border: 1px solid rgba(0, 0, 0, 0.06);
        width: 100%;
      }
    }
    &:last-child {
      margin-top: 24px;
    }
  }
}
