.dp-accounts {
  .filter-component {
    .search-box {
      margin-bottom: 0px;
    }
    .add-account {
      cursor: pointer;
      .title {
        margin-bottom: 0px;
      }
    }
  }
  .account-table {
    margin-top: 20px;
  }
}
