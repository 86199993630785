.select-btn {
  .ant-select-selector {
    .ant-select-selection-item {
      height: 100%;
      margin-top: -0px !important;
      font-weight: 600;
      padding-top: 12px !important;
    }
  }
}

.kyc-list-filter {
  margin: 24px 0px !important;
  .search-filter, .date-range-filter {
    margin-bottom: 0px;
  }
  
}