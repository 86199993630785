.dashboard__payment__stats__tabs {
  width: 100%;
  border-radius: 15px !important;
  height: 70px;
  padding: 10px 20px;
}

.dashboard__payment__tabs__container {
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  gap: 20px;
}

.stats__green__background {
  background-color: #e2f3ec !important;
}

.stats__number {
  font-size: 16px;
  font-weight: 800;
}

.stats_number_green {
  color: #1cc67b !important;
}
