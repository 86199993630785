.mainMapContainer {
  height: 400px;
  width: 100%;
  position: relative;
}

.recenter--map {
  position: absolute;
  right: 10px;
  top: 10px;
}

.spinner--map {
  display: flex;
  justify-content: center;
  width: 100%;
}
