.video-react .video-react-poster {
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  background-color: #000000;
  cursor: pointer;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;

  img {
    display: block;
    vertical-align: middle;
    margin: 0 auto;
    max-height: 100%;
    padding: 0;
    width: 100%;
  }

}