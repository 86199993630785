.full--screen {
	position: fixed;
	z-index: 2;
	background: #ffffffe0;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	margin-top: 0px !important;
}
