.upload__container {
  margin-top: 10px;
  height: 120px;
  width: 380px;
  justify-content: center;
  align-items: center;
}

.topright__container__uploadKyc {
  //   margin-left: 40px;
}

.select__center__label .ant-select-selector .ant-select-selection-item {
  padding-top: 0px !important;
  margin-top: 0px !important;
}

.upload__csv__btn {
    margin-left: 20px;
}

.uploaded__csv_container {
  margin-top: 15px;
}

.uploaded__csv_btn {
  padding: 10px;
}

.csv__result__container {
  margin-top: 15px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.csv__result__container__top {
  min-height: 200px;
  width: 100%;
}

.csv__result__container__bottom {
  // background: cyan;
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  gap:20px;

}

.csv__result__tabs {
  // background: red;
  width: 100%;
  // border: 1px solid;
  border-radius: 15px !important;
  height: 70px;
  padding: 10px 20px;
  text-transform: uppercase;
  cursor: pointer;
}
