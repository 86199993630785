.voucher-details {
    .headers {
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;
        .right {
            display: flex;
            flex-direction: column;
        }
    }
    .voucher-details-table {
        .ant-table-summary {
            background-color: transparent;
            .summary-cell {
                font-weight: 600;
            }
        }
    }
}