.video-react {
  .video-react-playback-rate {
    .video-react-playback-rate-value {
      line-height: 3em;
      text-align: center;
    }

    .video-react-menu {
      width: 4em;
      left: 0em;
    }
  }
}
