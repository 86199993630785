.customer-single {
  .transaction {
    margin-top: 20px;
    .top-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .date-filters {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin: 20px 0px;

      .date-range {
        width: 18vw;
        margin-left: 16px;
      }
      .single-date {
        svg {
            margin-left: 8px;
        }
      }
    }
  }
}
