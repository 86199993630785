.video-react {
  .video-react-closed-caption {
    cursor: pointer;
    @include flex(none);
    @extend .video-react-icon;
    @extend .video-react-icon-closed-caption;
  }

  video::-webkit-media-text-track-container {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
}
