.video-react {
  .video-react-menu-button-inline {
    @include transition(all 0.4s);
    overflow: hidden;

    &:before {
      // Icon pseudoelement has a different base font size (1.8em), so we need to
      // account for that in the width. 4em (standard button width) divided by 1.8
      // to get the same button width as normal.
      width: 2.222222222em;
    }

    &:hover,
    &:focus,
    &.video-react-slider-active {
      width: 12em;

      .video-react-menu {
        display: block;
        opacity: 1;
      }

    }

    &.video-react-slider-active {
      @include transition(none);
    }

    .video-react-menu {
      opacity: 0;
      height: 100%;
      width: auto;

      position: absolute;
      left: 4em;
      top: 0;

      padding: 0;
      margin: 0;

      @include transition(all 0.4s);
    }

    .video-react-menu-content {
      width: auto;
      height: 100%;
      margin: 0;
      overflow: hidden;
    }
  }
}


.video-react-no-flex .video-react-menu-button-inline {
  .video-react-menu {
    display: block;
    opacity: 1;
    position: relative;
    width: auto;
  }

  &:hover,
  &:focus,
  &.video-react-slider-active {
    width: auto;
  }
}