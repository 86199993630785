.video-react {

  .video-react-time-control {
    @include flex(none);
    font-size: 1em;
    line-height: 3em;
    min-width: 2em;
    width: auto;
    padding-left: 1em;
    padding-right: 1em;
  }

  .video-react-time-divider {
    line-height: 3em;
    min-width: initial;
    padding: 0;
  }
}
