.rounded__search {
	&.ant-input-search {
		border-radius: 10px;
		.ant-input {
			border-top-left-radius: 10px;
			border-bottom-left-radius: 10px;
		}
		.ant-input-group-addon {
			.ant-input-search-button {
				border-top-right-radius: 10px !important;
				border-bottom-right-radius: 10px !important;
			}
		}
	}
}
