.float-label {
	position: relative;
	margin-bottom: 18px;
	input {
		padding-top: 28px !important;
		height: 68px;
	}
	.ant-select-selection-item {
		padding-top: 22px !important;
	}
}

.f-label {
	font-size: 12px !important;
	font-weight: normal;
	position: absolute;
	pointer-events: none;
	color: #767997;
	left: 12px;
	top: 12px;
	transition: 0.2s ease all;
	z-index: 50;
}

.f-label-float {
	// top: 6px;
	font-size: 10px;
}
