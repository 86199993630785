.dp-customer {
  .filter {
    .filter-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      .content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &.search {
          .search-btn {
            margin-left: 16px;
          }
          .search-field {
            margin-bottom: 0px;
            width: 20vw;
          }
        }
        .export-btn {
          font-weight: 400;
          margin-right: 16px;
        }
        .import-btn {
          margin-right: 16px;
          svg {
            margin-left: 8px;
          }
        }
      }
    }
    .filter-bottom {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 16px;

      .accounts {
        svg {
          margin-left: 8px;
        }
      }

      .date-range {
        width: 12vw;
        margin-left: 16px;
      }
    }
  }
  .customer-table {
    margin-top: 20px;
    width: 100%;
    overflow-x: scroll;
  }
}

.boid-validation-input {
  &[value = "13013200"] {
    color: #bfbfbf;
  }
}
