.select-btn {
  .ant-select-selector {
    .ant-select-selection-item {
      height: 100%;
      margin-top: -0px !important;
      font-weight: 600;
      padding-top: 12px;
    }
  }
}

.user-list-filter {
  .search-btn {
    margin-top: 32px;
  }
}
