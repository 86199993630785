.video-react .video-react-fullscreen-control {
  cursor: pointer;
  @include flex(none);
}

.video-react.video-react-fullscreen {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
}