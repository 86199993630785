.reporting {
  .generate {
    margin-bottom: 16px;

    .card-items {

      .generate-field {

        .add-account {
          width: 100%;
        }

        .date-range {
          margin-right: 16px;
          width: 100%;
        }
      }
    }

    .generate-btn {
      display: flex;
      align-items: center;

      svg {
        margin-right: 8px;
      }
    }
    .export-btn {
      font-weight: 400;
      width: 100%;
    }
  }

  .headers {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .details {
    margin: 35px 0px;

    .value {
      font-weight: 600;
      margin-left: 12px;
    }
  }

  .report-table {
    .ant-table-summary {
      background-color: transparent;

      .summaryCell {
        background-color: #e2f3ec;
        color: #1cc67b;

        &.first {
          border-top-left-radius: 15px;
          border-bottom-left-radius: 15px;
        }

        &.last {
          border-top-right-radius: 15px;
          border-bottom-right-radius: 15px;
        }
      }

      .ant-table-cell {
        border-bottom: 0px;
      }
    }
  }
}

.exportModal {
  .content {
    width: 100%;

    .pdf-btn {
      margin-right: 16px;
    }
  }
}